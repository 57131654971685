import { Component, Input } from '@angular/core';
import { Icons } from '../../../../../assets/icons';

@Component({
  selector: 'app-icon',
  template: `<span *ngIf="variant" [innerHTML]="variant | safeHtml" class="block"></span>`
})
export class IconsComponent {
  @Input() icon!: string;

  get variant(){
    return Icons[this.icon] || ''
  }
}
