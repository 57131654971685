import { Component, ContentChildren, AfterContentInit, QueryList } from '@angular/core';
import { BreadcrumbItemComponent } from '../../atoms/breadcrumb-item/breadcrumb-item.component';

@Component({
  selector: 'app-breadcrumb',
  template: `
    <div class="flex items-center gap-1">
      <ng-content></ng-content>
    </div>
  `
})
export class BreadcrumbComponent implements AfterContentInit  {
  @ContentChildren(BreadcrumbItemComponent) items!: QueryList<BreadcrumbItemComponent>;

  ngAfterContentInit() {
    const items = this.items.toArray();
    items[0].first = true;
    items[items.length - 1].current = true;
  }

  constructor() { }

}
