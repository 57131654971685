import { Component, OnInit } from '@angular/core';
import { ClientService } from '../../../../services/client.service';
import { CreditReportService } from '../../../../services/creditReport.service';
import { DocumentInformation } from '../../../../shared/models/documentInformation.model';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html'
})
export class AvatarComponent implements OnInit {
  creditReport: DocumentInformation;
  user: any;
  menuOpened = false;
  loadingBtn = false;

  constructor(
    private readonly clientService: ClientService,
    private readonly creditReportService: CreditReportService
  ) { }

  ngOnInit(): void {
    this.user = this.clientService.user;
    this.getCreditReport();
  }

  getCreditReport() {
    this.creditReportService.getListOfInforms().subscribe((reports) => {
      if (reports) {
        this.creditReport = reports[0];
      }
    });
  }

  downloadDocument() {
    if(this.creditReport){
      this.creditReportService.getDocument('', this.creditReport.documentId).subscribe((res: any) => {
        const base64 = res.base64;
        const arrayBuffer = this.base64ToArrayBuffer(base64);
        const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        window.open(url, '_blank');
      });
    }
  }
  
    base64ToArrayBuffer(base64: string): ArrayBuffer {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return bytes.buffer;
    }

  toggleMenu() {
    this.menuOpened = !this.menuOpened;
  }

  logout() {
    this.loadingBtn = true;
    this.clientService.logout();
  }

  onKeydown(event: KeyboardEvent, func: any): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      func();
    }
  }

  onLogout(event: KeyboardEvent): void {
    this.onKeydown(event, this.logout());
  }

  onReport(event: KeyboardEvent): void {
    this.onKeydown(event, this.downloadDocument());
  }

}
