import { Component, Input, OnInit } from '@angular/core';
import { ContractService } from '../../../../services/contract.service';
import { Contract } from '../../../../shared/models/contract.model';
import { DDCDocumentInformations } from '../../../../shared/models/ddcDocumentInformations.model';
import { DocumentInformation } from '../../../../shared/models/documentInformation.model';

enum CONTRACT_SITUATIONS {
  A = 'Em atraso',
  A2 = 'Contrato à Vencer',
  V = 'Contrato ativo',
  C = 'Cancelado',
  L = 'Liquidado',
  R = 'Renegociado',
  D = 'Aditamento',
};

@Component({
  selector: 'app-contract-details-section',
  templateUrl: './contract-details-section.component.html',
})
export class ContractDetailsSectionComponent implements OnInit {
  @Input() contract: Contract;
  @Input() ccbDocument: DocumentInformation;
  @Input() ddcDocument: DDCDocumentInformations;
  @Input() aditiveDocument: DocumentInformation;

  contractProperties = [];

  constructor(private contractService: ContractService) { }

  ngOnInit() {
    this.setProperties();
  }

  setProperties() {
    const paymentDate = new Date(
      this.contract.tranches[0].dueDate + 'T00:00:00',
    ).getDate();
    this.contractProperties = [
      {
        label: 'Modalidade',
        value: this.contract.productDescription,
      },
      {
        label: 'Situação do contrato',
        value: CONTRACT_SITUATIONS[this.contract.situation] || '',
      },
      {
        label: 'Valor recebido',
        value: this.contract.releasedValue?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
      },
      {
        label: 'Total de parcelas',
        value: `
          ${this.contract.contractPeriod} × de 
          ${this.contract.tranches[0].installmentAmount?.toLocaleString(
          'pt-BR',
          { style: 'currency', currency: 'BRL' },
        )}
        `,
      },
      {
        label: 'Data de vencimento das parcelas',
        value: `Todo dia ${paymentDate}`,
      },
      {
        label: 'Total a pagar com juros',
        value: this.contract.totalValueToPay?.toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        }),
      },
    ];
  }

  handlePropertyValue(value: string){
    return value ? value : '---';
  }

  downloadDocument(documentId: number) {
    this.contractService.downloadDocument(documentId).subscribe((res: any) => {
      const base64 = res.base64;
      const arrayBuffer = this.base64ToArrayBuffer(base64);
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      window.open(url, '_blank');
    });
  }

 base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  downloadDDC() {
    const arrayBuffer = this.base64ToArrayBuffer(this.ddcDocument.contract);
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  }
}
