import { MatSnackBar } from '@angular/material/snack-bar';
import { Component, Input } from '@angular/core';
import { PixQRCode } from '../../../../shared/models/PixQRCode.model';

@Component({
  selector: 'app-pix-payment',
  templateUrl: './pix-payment.component.html',
})

export class PixPaymentComponent {
  @Input() pixData!: PixQRCode;
  @Input() paymentLoading: boolean;

  constructor(
    private readonly snackBar: MatSnackBar,
    ) { }

  getPixText() {
    return this.pixData.paymentInformation.pix.qrCode.emv.toString();
  }

  getPixQRCode() {
    const base64 = this.pixData.paymentInformation.pix.qrCode.base64;

    return `data:image/png;base64,${base64}`
  }

  copyPixText() {
    this.copyToClipboard(this.getPixText());
    this.openSnackBar('Pix cópia/cola copiado!');
  }

  openSnackBar(message: string) {
    this.snackBar.open(message, null, { duration: 2000 });
  }

  copyToClipboard(text: string) {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }
}
