import {
  Component,
  ElementRef,
  OnInit,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { finalize } from 'rxjs/operators';
import { ClientService } from '../../services/client.service';
import { Utils } from '../../shared/utils/utils';
import { Subscription } from 'rxjs';
import { TimerStateService } from '../../services/timer-state.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('firstCode', { static: false }) firstCode: ElementRef;
  sendcpf: UntypedFormGroup;
  sendSms: UntypedFormGroup;
  isCpf: boolean;
  isCpfValid: boolean;
  cpfUser: string;
  userPhoneNumbers: string;
  isValidCodSms: boolean = true;
  isTimeOut: boolean = false;
  existeUser: boolean;
  externalId: string;
  isAuth: boolean = false;
  loadingBtn: boolean = false;
  loadingSmsBtn: boolean = false;
  params;
  private readonly subscription: Subscription;
  constructor(
    private readonly fb: UntypedFormBuilder,
    private readonly router: Router,
    private readonly clientService: ClientService,
    private readonly reCaptchaV3Service: ReCaptchaV3Service,
    private readonly activatedRoute: ActivatedRoute,
    private readonly timerStateService: TimerStateService,
  ) {
    this.clientService.logoutWithoutRouter();
    this.subscription = this.timerStateService.isTimeOut$.subscribe(
      (isTimeOut) => {
        if (isTimeOut) {
          this.isTimeOut = true;
          this.submitSms();
        } else {
          this.isTimeOut = false;
        }
      },
    );
  }

  get f() {
    return this.sendcpf.controls;
  }

  ngOnInit(): void {
    this.params = this.activatedRoute.snapshot.queryParams;
    this.sendcpf = this.fb.group({
      cpf: ['', [Validators.required, Utils.validatesCpf]],
    });

    this.sendSms = this.fb.group({
      cod1: [
        '',
        [Validators.required, Validators.minLength(1), Validators.maxLength(1)],
      ],
      cod2: [
        '',
        [Validators.required, Validators.minLength(1), Validators.maxLength(1)],
      ],
      cod3: [
        '',
        [Validators.required, Validators.minLength(1), Validators.maxLength(1)],
      ],
      cod4: [
        '',
        [Validators.required, Validators.minLength(1), Validators.maxLength(1)],
      ],
      cod5: [
        '',
        [Validators.required, Validators.minLength(1), Validators.maxLength(1)],
      ],
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  async sendCPF(): Promise<void> {
    this.isTimeOut = false;
    this.loadingBtn = true;
    if (this.sendcpf.invalid) {
      this.loadingBtn = false;
      return;
    }
    this.cpfUser = this.sendcpf.value.cpf.replace(/\D/g, '');

    this.reCaptchaV3Service.execute('login').subscribe(
      (recaptcha) => {
        let customer = { documentNumber: this.cpfUser, recaptcha: recaptcha };
        this.clientService.getClient(customer)
        .pipe(finalize(() => (this.loadingBtn = false)))
        .subscribe(
          (res: any) => {
            let client = res.body;
            if (client.externalId) {
              this.existeUser = true;
              this.externalId = client.externalId;
              this.userPhoneNumbers = this.formatedPhoneNumbers(client.phones);
            }
          },
          () => this.router.navigate(['error', '404']),
        )
      }
    );
  }

  submitSms(): void {
    this.loadingSmsBtn = true;
    if (this.sendSms.invalid) {
      this.loadingSmsBtn = false;
      return;
    }
    const codigoSms: string =
      this.sendSms.value.cod1 +
      this.sendSms.value.cod2 +
      this.sendSms.value.cod3 +
      this.sendSms.value.cod4 +
      this.sendSms.value.cod5;

    this.reCaptchaV3Service.execute('sms').subscribe(
      (recaptcha) => {
        const dataBody = { code: codigoSms, externalId: this.externalId, recaptcha: recaptcha };
        this.clientService.getClientBySms(dataBody).subscribe(
          () => {
            this.timerStateService.setTimeOutState(false);
            this.isValidCodSms = true;
          },
          (err) => {
            this.loadingSmsBtn = false;
            this.isValidCodSms = false;
          },
        )
      }
    );
  }

  move(fromInput, toInput) {
    let length = fromInput.value.length;
    if (length == 1) {
      toInput.focus();
    }
  }

  moveBack(fromInput, toInput) {
    let length = fromInput.value.length;
    if (length == 0) {
      toInput.focus();
    }
  }

  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  formatedPhoneNumbers(phones: Array<string>): string {
    const formattedNumbers = phones.map((phone, index, array) => {
      if (index === array.length - 2) {
        return ` e ${phone}`;
      } else if (index < array.length - 2) {
        return `${phone},`;
      } else {
        return phone;
      }
    });
    return formattedNumbers.join(' ');
  }
}