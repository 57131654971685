import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import {
  RECAPTCHA_V3_SITE_KEY,
  ReCaptchaV3Service,
  RecaptchaModule,
} from 'ng-recaptcha';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DialogAlertComponent } from './components/dialog-alert/dialog-alert.component';
import { LoginComponent } from './components/login/login.component';
import { MessageViewComponent } from './components/message-view/message-view.component';
import { PaymentComponent } from './components/payment/payment.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SplitScreenLayoutComponent } from './components/new/layouts/split-screen-layout/split-screen-layout.component';
import { AuthGuard } from './guards/auth.guard';
import { AuthInterceptorService } from './interceptors/auth.interceptor';
import { MaterialModule } from './shared/material/material.module';
import { MasksDirective } from './shared/utils/masks.directive';
import { ErrorComponent } from './views/error/error.component';
import { HomeComponent } from './views/home/home.component';
import { PaymentsComponent } from './views/payments/payments.component';
import { CpfCnpjPipe } from './shared/pipes/cpf-cnpj.pipe';
import { PtBrMatPaginatorIntl } from './shared/material/pt-br-mat-paginator-intl';
import { ContractsHubComponent } from './views/contracts-hub/contracts-hub.component';
import { TitleComponent } from './components/new/atoms/title/title.component';
import { ContractCardComponent } from './components/new/organisms/contract-card/contract-card.component';
import { ButtonComponent } from './components/new/molecules/button/button.component';
import { CenterColumnLayoutComponent } from './components/new/layouts/center-column-layout/center-column-layout.component';
import { ChipComponent } from './components/new/atoms/chip/chip.component';
import { NavbarComponent } from './components/new/organisms/navbar/navbar.component';
import { AvatarComponent } from './components/new/molecules/avatar/avatar.component';
import { ChipGroupComponent } from './components/new/molecules/chip-group/chip-group.component';
import { IconsComponent } from './components/new/atoms/icons/icons.component';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { ContractPageComponent } from './views/contract-page/contract-page.component';
import { InteractiveCardComponent } from './components/new/molecules/interactive-card/interactive-card.component';
import { SupportChannelsSectionComponent } from './components/new/organisms/support-channels-section/support-channels-section.component';
import { ContractDetailsSectionComponent } from './components/new/organisms/contract-details-section/contract-details-section.component';
import { DatePipe } from './shared/pipes/date.pipe';
import { InstallmentTableSectionComponent } from './components/new/organisms/installment-table-section/installment-table-section.component';
import { ProgressBarComponent } from './components/new/molecules/progress-bar/progress-bar.component';
import { CheckboxComponent } from './components/new/atoms/checkbox/checkbox.component';
import { BreadcrumbItemComponent } from './components/new/atoms/breadcrumb-item/breadcrumb-item.component';
import { BreadcrumbComponent } from './components/new/molecules/breadcrumb/breadcrumb.component';
import { InstallmentAdvanceTableComponent } from './components/new/organisms/installment-advance-table/installment-advance-table.component';
import { TimerComponent } from './components/new/atoms/timer/timer.component';
import { SkeletonComponent } from './components/new/atoms/skeleton/skeleton.component';
import { ContractCardSkeletonComponent } from './components/new/molecules/contract-card-skeleton/contract-card-skeleton.component';
import { BilletPaymentComponent } from './components/new/organisms/billet-payment/billet-payment.component';
import { PixPaymentComponent } from './components/new/organisms/pix-payment/pix-payment.component';
import { ModalComponent } from './components/new/molecules/modal/modal.component';

registerLocaleData(ptBr);
export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [
    AppComponent,
    PaymentsComponent,
    DialogAlertComponent,
    ErrorComponent,
    HomeComponent,
    LoginComponent,
    MasksDirective,
    MessageViewComponent,
    PaymentComponent,
    ProfileComponent,
    SplitScreenLayoutComponent,
    CpfCnpjPipe,
    ContractsHubComponent,
    TitleComponent,
    ContractCardComponent,
    ButtonComponent,
    CenterColumnLayoutComponent,
    ChipComponent,
    NavbarComponent,
    AvatarComponent,
    ChipGroupComponent,
    IconsComponent,
    SafeHtmlPipe,
    ContractPageComponent,
    InteractiveCardComponent,
    SupportChannelsSectionComponent,
    ContractDetailsSectionComponent,
    DatePipe,
    InstallmentTableSectionComponent,
    ProgressBarComponent,
    CheckboxComponent,
    BreadcrumbItemComponent,
    BreadcrumbComponent,
    InstallmentAdvanceTableComponent,
    TimerComponent,
    SkeletonComponent,
    ContractCardSkeletonComponent,
    BilletPaymentComponent,
    PixPaymentComponent,
    ModalComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        MaterialModule,
        LottieModule.forRoot({ player: playerFactory }),
        ReactiveFormsModule,
        RecaptchaModule,
        CurrencyMaskModule
    ],
    exports: [MasksDirective],
    providers: [
        AuthGuard,
        ReCaptchaV3Service,
        PtBrMatPaginatorIntl,
        { provide: LOCALE_ID, useValue: 'pt' },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true,
        },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchSiteKey },
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
