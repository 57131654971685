import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-breadcrumb-item',
  template: `
    <span class="flex items-center gap-1">
      <app-icon *ngIf="!first" [icon]="'chevron-right'" class="w-3 h-3 fill-gray-800"></app-icon>
      <a (click)="goToPage()" [class.cursor-pointer]="url" [class.text-gray-900]="current" [class.text-gray-800]="!current" class="text-sm font-bold p-1">
        <ng-content></ng-content>
      </a>
    </span>
  `
})
export class BreadcrumbItemComponent{
  @Input() url: string;
  @Input() first: boolean = false;
  @Input() current: boolean = false;

  constructor(private readonly router: Router) { }

  goToPage(){
    if(this.url) this.router.navigate([this.url]);
  }
}
