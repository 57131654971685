import { Component, OnInit } from '@angular/core';
import { ContractService } from '../../services/contract.service';
import { BilletModel } from '../../shared/models/billet.model';
import { PixQRCode } from '../../shared/models/PixQRCode.model';
import { Payment } from '../../shared/models/payment.model';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
})
export class PaymentsComponent implements OnInit {
  pix: PixQRCode | any;
  billet: BilletModel | any;
  payment: Observable<Payment>;
  paymentByPix: boolean = false;
  paymentByBillet: boolean = false;
  paymentUnavailable: boolean = false;
  contractId: string;

  loadingBtn: boolean = false;
  loadingPaymentInfo: boolean = true;
  loadingPix: boolean = true;
  loadingBillet: boolean = false;

  constructor(
    private readonly contractService: ContractService,
  ) { }

  ngOnInit(): void {
    this.payment = this.contractService.getPayment();
    this.payment.subscribe((payment) => { this.contractId = payment.contract; } );

    this.generatePix();
  }

  generatePix() {
    const response = this.contractService.getPix()
    response.pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => {
        this.loadingPaymentInfo = false;
        this.loadingPix = false;
      })
    ).subscribe((res) => {
      if (res.status !== 406) {
        this.paymentByPix = true;
        this.pix = res;
      }
    });
  }

  generateBillet() {
    this.loadingPaymentInfo = true;
    this.loadingBillet = true;
    this.loadingBtn = true;

    const response = this.contractService.getBilletData()
    response.pipe(
      catchError((error) => {
        this.paymentUnavailable = true;
        return throwError(error);
      }),
      finalize(() => {
        this.loadingPaymentInfo = false;
        this.loadingBillet = false;
        this.loadingBtn = false;
      })
    ).subscribe((res) => {
      this.paymentByBillet = true;
      this.billet = res;
    });
  }

  get billetBtnEnabled() {
    return !this.loadingPix &&
      !this.paymentByBillet &&
      !this.paymentUnavailable
  }
}