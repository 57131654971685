export class Utils {
  static validatesCpf(cpf: any) {
    const cpfClean = cpf.value.replace(/\D/g, '');

    if (cpfClean && cpfClean.length == 11) {
      let numbers, digits, sum, i, result, equalDigits;
      equalDigits = 1;

      for (i = 0; i < cpfClean.length - 1; i++) {
        if (cpfClean.charAt(i) !== cpfClean.charAt(i + 1)) {
          equalDigits = 0;
          break;
        }
      }

      if (!equalDigits) {
        numbers = cpfClean.substring(0, 9);
        digits = cpfClean.substring(9);
        sum = 0;
        for (i = 10; i > 1; i--) {
          sum += numbers.charAt(10 - i) * i;
        }

        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(0))) {
          return { cpfNotValid: true };
        }
        numbers = cpfClean.substring(0, 10);
        sum = 0;

        for (i = 11; i > 1; i--) {
          sum += numbers.charAt(11 - i) * i;
        }
        result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

        if (result !== Number(digits.charAt(1))) {
          return { cpfNotValid: true };
        }
        return null;
      } else {
        return { cpfNotValid: true };
      }
    }
    return { cpfNotValid: false };
  }
}

export const arrayRange = (start: number, stop: number, step = 1) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );
