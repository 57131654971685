import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ClientService } from '../services/client.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  private readonly nonCriticalEndpoints: string[] = [
    `${environment.apiAuth}/me`,
    `${environment.api}/manifestations/extract`,
    `${environment.api}/payments`
  ];

  constructor(private readonly service: ClientService, private readonly router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = request.headers.set('Cache-Control', 'no-cache')
      .set('Content-Type', 'application/json');
    if (this.service.isAuthenticad() && this.router.url !== '/') {
      headers = request.headers.set('x-token', `${this.service.user.token}`)
        .set("Correlation_Id", this.service.user.documentNumber);
    } 
    request = request.clone({ headers });
    return next.handle(request)
      .pipe(
        catchError((err) => this.handleError(request, next, err))
      );
  }
  
  private isNonCriticalEndpoint(url: string): boolean {
    return this.nonCriticalEndpoints.some(endpoint => url.includes(endpoint));
  }

  private handleError(request: HttpRequest<any>, next: HttpHandler, responseError: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (this.isNonCriticalEndpoint(request.url)) return throwError(responseError);

    if (responseError.status === 401) {
      this.service.logout();
      this.router.navigate(['/']);
      return throwError(responseError);
    } else if (responseError.status === 400 && this.router.url !== '/') {
      this.router.navigate(['/error/400']);
      return throwError(responseError);
    } else if (responseError.status === 406) {
      return throwError(responseError);
    } else if (`${responseError.status}`.startsWith('5')) {
      this.router.navigate(['/error/500']);
      return throwError(responseError);
    } else {
      throw responseError;
    }
  }
}