import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { ErrorComponent } from './views/error/error.component';
import { HomeComponent } from './views/home/home.component';
import { ContractsHubComponent } from './views/contracts-hub/contracts-hub.component';
import { ContractPageComponent } from './views/contract-page/contract-page.component';
import { PaymentsComponent } from './views/payments/payments.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'error/:status',
    component: ErrorComponent,
  },
  {
    path: 'pagamentos',
    component: PaymentsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'meus-emprestimos',
    component: ContractsHubComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'emprestimo/:contractNumber',
    component: ContractPageComponent,
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: 'error/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }