import { Component, Input, OnInit } from '@angular/core';
import { ContractService } from '../../../../services/contract.service';
import { Tranch } from '../../../../shared/models/tranch.model';

@Component({
  selector: 'app-installment-advance-table',
  templateUrl: './installment-advance-table.component.html',
  styleUrls: ['./installment-advance-table.component.scss'],
})
export class InstallmentAdvanceTableComponent implements OnInit {
  @Input() openInstallments!: Tranch[];
  @Input() numOfInstallments: number;
  @Input() paymentFunc: Function;
  @Input() daysToBirthdayMonth: number;

  isListReversed: boolean = false;
  nextInstallment: string = null;
  lastInstallment: string;
  loadingBtn: boolean = false;

  selectedItems = [];
  mandatoryInstallments = [];
  disabledItems = [];
  mandatoryPayment: number = 0;

  constructor(public contractService: ContractService) { }

  ngOnInit(): void {
    this.checkListOrder();
    this.selectKeyInstallments();
    this.setMandatoryPayment();
    this.setDisabledInstallments();
  };

  selectKeyInstallments() {
    let nextInstallment: Tranch = null;

    this.lastInstallment = this.isListReversed ? this.openInstallments[0].tranchCode :
      this.openInstallments[this.openInstallments.length - 1].tranchCode;

    for (let installment of this.openInstallments) {
      if (this.contractService.isMandatoryForAdvance(installment)) {
        this.mandatoryInstallments.push(installment.tranchCode);
      } else {
        if (!nextInstallment)
          nextInstallment = installment;
        else {
          const installmentDate = new Date(installment.dueDate);
          const nextInstallmentDate = new Date(nextInstallment.dueDate);

          if (installmentDate.getTime() < nextInstallmentDate.getTime())
            nextInstallment = installment;
        }
      }
    }

    this.nextInstallment = nextInstallment?.tranchCode;
  }

  setMandatoryPayment() {
    this.mandatoryPayment = this.openInstallments
      .filter((installment) =>
        this.mandatoryInstallments.includes(installment.tranchCode),
      )
      .map((installment) => installment.outstandingBalance)
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }

  setDisabledInstallments() {
    this.disableUnselectedInstallments();

    if (this.disabledItems.length) {
      if (this.isLastSelected) {
        if (this.isListReversed) this.disabledItems.shift();
        else this.disabledItems.pop();
      } else if (this.isNextSelected) {
        if (this.isListReversed) this.disabledItems.pop();
        else this.disabledItems.shift();
      } else {
        this.disabledItems.pop();
        this.disabledItems.shift();
      }
    }
  }

  disableUnselectedInstallments() {
    const notMandatory = this.openInstallments.filter(
      (item) => !this.mandatoryInstallments.includes(item.tranchCode),
    );

    const unselectedItems = notMandatory.filter(
      (item) => !this.selectedItems.includes(item.tranchCode),
    );

    this.disabledItems = unselectedItems;
  }

  get totalValueToPay() {
    return (
      this.mandatoryPayment +
      this.openInstallments
        .filter((installment) =>
          this.selectedItems.includes(installment.tranchCode),
        )
        .map((installment) => installment.outstandingBalance)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    );
  }

  get isNextSelected() {
    return this.selectedItems.includes(this.nextInstallment);
  }

  get isLastSelected() {
    return this.selectedItems.includes(this.lastInstallment);
  }

  isInstallmentSelected(tranchCode: string): boolean {
    return (
      this.mandatoryInstallments.includes(tranchCode) ||
      this.selectedItems.includes(tranchCode)
    );
  }

  isInstallmentDisabled(code: string): boolean {
    return this.disabledItems.some((item) => item.tranchCode === code) ||
      this.mandatoryInstallments.includes(code)
  }

  isInstallmentDisabledForSelection(code: string): boolean {
    return this.disabledItems.some((item) => item.tranchCode === code);
  }

  handleSelectInstallment(
    checked: boolean,
    tranchCode?: string,
    selectAll?: boolean,
  ) {
    if (selectAll) {
      if (checked)
        this.selectAllInstallments();
      else
        this.unselectAllInstallments();
    } else {
      if (checked) this.addToSelectedInstallments(tranchCode);
      else {
        if (tranchCode == this.nextInstallment && this.isLastSelected) {
          this.removeFromSelectedInstallments(tranchCode);
        }

        this.updateSelectedItems(tranchCode);
      }
    }

    this.setDisabledInstallments();
  }

  removeFromSelectedInstallments(tranchCode: string) {
    const index = this.selectedItems.indexOf(tranchCode);
    if (index > -1) this.selectedItems.splice(index, 1);
  }

  updateSelectedItems(tranchCode: string): void {
    this.selectedItems = this.selectedItems.filter((item) => {
      if (this.isNextSelected) return Number(item) < Number(tranchCode);
      else if (this.isLastSelected) return Number(item) > Number(tranchCode);
    });
  }

  addToSelectedInstallments(tranchCode: string) {
    if (this.selectedItems.includes(tranchCode)) return;

    this.selectedItems.push(tranchCode);
  }

  selectAllInstallments() {
    this.openInstallments.forEach((installment) => {
      if (!this.mandatoryInstallments.includes(installment.tranchCode))
        this.selectedItems.push(installment.tranchCode);
    });
  }

  unselectAllInstallments() {
    this.selectedItems = [];
  }

  formatInstallmentCode(code: string) {
    return Number(code).toString();
  }

  toCode(code: number) {
    return code.toString().padStart(3, '0');
  }

  onClickPayment() {
    if (this.paymentFunc) {
      this.loadingBtn = true;
      this.paymentFunc(
        [...this.mandatoryInstallments, ...this.selectedItems],
        this.totalValueToPay,
      );
    }
  }

  checkListOrder() {
    const installmentDate = new Date(this.openInstallments[0].dueDate);
    const lastInstallmentDate = new Date(this.openInstallments[this.openInstallments.length - 1].dueDate);

    if (installmentDate.getTime() > lastInstallmentDate.getTime())
      this.isListReversed = true;
  }
}
