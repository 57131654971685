import { MatDialog } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
  providedIn: 'root',
})
export class ClientService {
  private readonly keyStorage = btoa(environment.clientId);

  user = this.getStorage();
  auth: boolean = this.user?.isAuth ?? false;
  loading;
  params;

  constructor(
    private readonly http: HttpClient,
    private readonly router: Router,
    public readonly dialogAlert: MatDialog,
    private readonly reCaptchaV3Service: ReCaptchaV3Service,
  ) { }

  getClient(data): Observable<any> {
    const cpf = this.formatDocument(data.documentNumber);

    return this.http
      .post(`${environment.apiAuth}/auth`, data, { observe: 'response' })
      .pipe(tap(() => this.setStorage({ cpf })));
  }

  getClientBySms(dataBody: any): Observable<any> {
    return this.http
      .put<any>(`${environment.apiAuth}/auth`, dataBody)
      .pipe(
        tap((result: any) => {
          const { token, documentNumber } = result;
          if (token) {
            const jwt = JSON.parse(atob(token.split('.')[1]));
            const exp = new Date(0).setUTCSeconds(jwt.exp);
            return this.http.get<any>(`${environment.apiAuth}/me`, { headers: new HttpHeaders({ token: token }) }).pipe(
              tap((res: any) => {
                let name = res.name;
                this.userIsAuth(true);
                this.setStorage({
                  token,
                  name,
                  documentNumber,
                  isAuth: true,
                  xTransactionId: this.UUID,
                  exp,
                });

                this.auth = true;
                this.user = this.getStorage();

                setTimeout(() => {
                  this.router.navigate(['/meus-emprestimos']);
                }, 1000);
              }),
            ).subscribe(); // Add this line to subscribe to the inner observable
          }
        }),
      );
  }

  getLogoImage(): Promise<string> {
    return this.http
      .get('assets/logo.txt', { responseType: 'text' })
      .toPromise();
  }

  logout() {
    this.reCaptchaV3Service.execute('logout').subscribe(
      (recaptcha) => {
        const jwt = JSON.parse(atob(this.getStorage().token.split('.')[1]));
        this.http
          .post(`${environment.apiAuth}/auth/${jwt.externalId}/logout`, { recaptcha })
          .subscribe(() => {
            this.router.navigate(['/']);
            localStorage.removeItem(this.keyStorage);
          });
      },
      () => this.router.navigate(['error', '404']),
    );
  }

  logoutWithoutRouter() {
    localStorage.removeItem(this.keyStorage);
  }

  userIsAuth(auth: boolean) {
    if (auth) {
      this.auth = true;
    }
  }

  isAuthenticad() {
      const user = this.getStorage();
      return user?.isAuth && this.user.exp > new Date().getTime();
  }

  setStorage(value: any) {
    const val = this.getStorage();
    localStorage.setItem(
      this.keyStorage,
      btoa(JSON.stringify(val ? { ...val, ...value } : value)),
    );
  }

  getStorage(): any {
    const value = localStorage.getItem(this.keyStorage);
    return value ? JSON.parse(atob(value)) : null;
  }

  formatDocument(value: string): string {
    return `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(
      6,
      9,
    )}-${value.substring(9)}`;
  }

  get UUID(): string {
    let d = new Date().getTime();
    let d2 =
      (typeof performance !== 'undefined' &&
        performance.now &&
        performance.now() * 1000) ||
      0;
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const crypto = window.crypto;
        let array = new Uint32Array(1);
        let r = crypto.getRandomValues(array)[0];
        if (d > 0) {
          r = (d + r) % 16 | 0;
          d = Math.floor(d / 16);
        } else {
          r = (d2 + r) % 16 | 0;
          d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      },
    );
  }
}
