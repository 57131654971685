import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientService } from '../../services/client.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
  accounts: any[];
  user: any;

  constructor(private service: ClientService, private router: Router) {}

  ngOnInit() {
    this.user = this.service.user;
  }

  getCapitalizeName(name: string): string {
    const index = name.indexOf(' ');
    name = index > 0 ? name.substring(0, index) : name;
    return name.charAt(0).toUpperCase() + name.substring(1).toLowerCase();
  }
}
