import { Observable } from 'rxjs';
import { Component, Input } from '@angular/core';
import { Payment } from '../../shared/models/payment.model';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
})
export class PaymentComponent  {
  @Input() payment: Observable<Payment>;
  @Input() paymentLoading: true;
}
