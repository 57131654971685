import { AfterContentInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { ChipComponent } from '../../atoms/chip/chip.component';

@Component({
  selector: 'app-chip-group',
  templateUrl: './chip-group.component.html'
})
export class ChipGroupComponent implements AfterContentInit {
  @Input() default = '';
  @ContentChildren(ChipComponent) chips!: QueryList<ChipComponent>;

  ngAfterContentInit() {
    this.chips.forEach(chip => {
      chip.selected.subscribe((value: string) => this.updateChips(value));
    });
    this.selectDefaultChip();
  }

  selectDefaultChip() {
    if (this.chips.length > 0) {
      if(this.default){
        const defaultChip =  this.chips.toArray().find(chip => chip.value == this.default);
        defaultChip.isSelected = true;
      } 
      else this.chips.toArray()[0].isSelected = true;
    }
  }

  updateChips(value: string) {
    this.chips.toArray().forEach(chip => {
      chip.isSelected = chip.value === value;
    });
  }
}