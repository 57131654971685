import { Component, Input } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BilletModel } from '../../../../shared/models/billet.model';

@Component({
  selector: 'app-billet-payment',
  templateUrl: './billet-payment.component.html',
})

export class BilletPaymentComponent {
  @Input() billetData!: BilletModel;
  @Input() paymentLoading: boolean;
  loadingBtn: boolean = false;

  constructor(
    private readonly snackBar: MatSnackBar,
    ) { }

  getDigitableLine(){
    return this.billetData.paymentInformation.slip.digitableLine?.toString();
  }

  copyText(): void {
    const digitableLine = (this.getDigitableLine() || '').replace(/\D/g, '');
    this.copyToClipboard(digitableLine);
    this.openSnackBar('Linha digitável copiada!');
  }

  openSnackBar(message: string): void {
    this.snackBar.open(message, null, { duration: 2000 });
  }

  copyToClipboard(text: string): void {
    const textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
  }

  downloadPdf(): void {
    this.loadingBtn = true;
    const url = this.billetData.paymentInformation.slip.urlDocument;
    if (url) {
      window.open(url, '_blank');
      this.loadingBtn = false;
    } else {
    console.error('URL do documento não está disponível.');
    }
  }
}
